import React from "react";
import { BrowserRouter, Routes as SwitchRoutes, Route } from "react-router-dom";

import routesName from "./config/routes";
import Providers, { AppProviders } from "./contexts";
import { AuthProvider } from "./contexts/auth";
import { PaymentGatewayProvider } from "./contexts/payment-gateway";
import { EntityProtected } from "./middlewares/entity-protected";
import Home from "./pages/home/main";
import HomeSchool from "./pages/home/school";
import LessonPage from "./pages/lessons";
import MessagesPage from "./pages/messages/messages";
import NotFoundPage from "./pages/not-found";
import PaymentCheckoutPage from "./pages/payment/checkout";
import PerfilSchoolPage from "./pages/perfil/school";
import PerfilStudentPage from "./pages/perfil/student";
import RecoveryPasswordPage from "./pages/recovery-password";
import SchoolAnalyse from "./pages/school-analyse";
import ClassroomPage from "./pages/school/classroom";
import CreateClassPage from "./pages/school/create-class";
import SchoolDashboard from "./pages/school/dashboard";
import SearchPage from "./pages/search/search";
import StudentDashboard from "./pages/student/dashboard";

export default function Routes() {
  return (
    <BrowserRouter>
      <Providers>
        {/* <RegionRestriction> */}
          <SwitchRoutes>
            <Route path="*" element={<NotFoundPage />} />

            <Route path={routesName.main} element={<Home />} />

            <Route
              path={routesName.recoveryPassword}
              element={<RecoveryPasswordPage />}
            />

            <Route path={routesName.schools} element={<HomeSchool />} />

            <Route path={routesName.search} element={<SearchPage />} />

            <Route path={routesName.lessons}>
              <Route path={routesName.lessonById} element={<LessonPage />} />
            </Route>

            <Route
              path={routesName.registerSchools}
              element={<PerfilSchoolPage />}
            />

            <Route
              path={routesName.registerStudents}
              element={<PerfilStudentPage />}
            />

            <Route
              path={routesName.schoolsAnalyse}
              element={<SchoolAnalyse />}
            />

            <Route
              path={routesName.students}
              element={
                <AppProviders>
                  <EntityProtected />
                </AppProviders>
              }
            >
              <Route
                path={routesName.studentsDashboard}
                element={<StudentDashboard />}
              />

              <Route
                path={routesName.studentsPerfil}
                element={<PerfilStudentPage />}
              />
            </Route>

            <Route
              path={routesName.schools}
              element={
                <AppProviders>
                  <EntityProtected />
                </AppProviders>
              }
            >
              <Route
                path={routesName.schoolsDashboard}
                element={<SchoolDashboard />}
              />

              <Route
                path={routesName.schoolsPerfil}
                element={<PerfilSchoolPage />}
              />

              <Route
                path={routesName.schoolsCreateClass}
                element={<CreateClassPage />}
              />

              <Route
                path={routesName.schoolsClassroomById}
                element={<ClassroomPage />}
              />
            </Route>

            <Route
              path={routesName.paymentCheckout}
              element={
                <PaymentGatewayProvider>
                  <PaymentCheckoutPage />
                </PaymentGatewayProvider>
              }
            />

            <Route
              path={routesName.messages}
              element={
                <AuthProvider>
                  <AppProviders>
                    <MessagesPage />
                  </AppProviders>
                </AuthProvider>
              }
            />
          </SwitchRoutes>
        {/* </RegionRestriction> */}
      </Providers>
    </BrowserRouter>
  );
}
