import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  Checkbox,
  Collapse,
  Flex,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";

import { ILocation, ILocationDetails, ISchool } from "@isurf-tech/types/domain";

import CollapseSectionText from "../../../../components/collapse-section-text";
import FormLocation from "../../../../components/forms/locations";
import Select from "../../../../components/select";
import TextField from "../../../../components/text-field";
import { useAuth } from "../../../../hooks/auth";
import { useResposiveness } from "../../../../hooks/responsiveness";
import useLocalStorage from "../../../../hooks/storage";
import {
  locationDetailsService,
  locationsService,
} from "../../../../services/api";

interface ILocationForm {
  location: {
    state: string;
    city: string;
    address: string;
    zip: string;
  };
  locationDetails: { label: string; value: string }[];
  landmark: string;
}

export const formSchema = {
  location: yup
    .object()
    .shape({
      zip: yup.string().required("Campo obrigatório"),
      state: yup.string().required("Campo obrigatório"),
      city: yup.string().required("Campo obrigatório"),
      address: yup.string().required("Campo obrigatório"),
    })
    .required("required"),
  landmark: yup.string().required("Campo obrigatório"),
  locationDetails: yup.array().of(
    yup.object().shape({
      label: yup.string().required("Campo obrigatório"),
      value: yup.string().required("Campo obrigatório"),
    })
  ),
};

export default function StepLocalization() {
  const { isMobile } = useResposiveness();
  const { user } = useAuth<ISchool>();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<ILocationForm>();
  const zip = watch("location.zip", "");
  const state = watch("location.state", "");
  const city = watch("location.city", "");
  const address = watch("location.address", "");
  const locationDetails = watch("locationDetails", []);

  const [locations, setLocations] = useState<ILocation[]>([]);
  const [manual, setManual] = useState(false);
  const [locationDetailsValues, setLocationDetailsValues] = useLocalStorage<
    ILocationDetails[]
  >("register-form-school-locations-details", []);
  const [newLocationDetail, setNewLocationDetail] = useState("");

  const handleSetLocation = useCallback(
    (location: ILocation) => {
      const { state, city, address, zip } = location || {};

      if (!state || !city || !address) return;

      const config = { shouldValidate: true };

      if (state) setValue("location.state", state, config);
      if (city) setValue("location.city", city, config);
      if (address) setValue("location.address", address, config);
      if (zip) setValue("location.zip", zip, config);
    },
    [setValue]
  );

  const handleSelectLocation = useCallback(
    (locationId: string) => {
      if (!locationId)
        return setValue("location", {
          state: "",
          city: "",
          address: "",
          zip: "",
        });

      const location = locations.find((location) => location.id === locationId);

      if (location) handleSetLocation(location);
    },
    [handleSetLocation, locations, setValue]
  );

  const loadDataManualLocation = async () => {
    setManual(!manual);
  };

  const getValueOption = () => {
    const location = locations.find(
      (location: ILocation) =>
        location?.state === state &&
        location?.city === city &&
        location?.address === address &&
        location?.zip === zip
    );

    if (location) return location.id;

    return "";
  };

  const renderOptionsLocation = (
    options: any
  ): { label: string; value: any }[] => {
    return options.map((location: any) => ({
      label: `${location.address}, ${location.city} - ${location.state}`,
      value: location.id,
    }));
  };

  const useLocationDetailsDefault = () => {
    if (locationDetailsValues?.length && user?.locationDetails?.length) {
      setValue("locationDetails", user.locationDetails);
    }
  };

  const cleanLocationDetailsInexistent = useCallback(() => {
    if (locationDetails.length) {
      setValue(
        "locationDetails",
        locationDetails.filter(
          (ld) =>
            !!locationDetailsValues?.find((l: any) => l.value === ld.value)
        )
      );
    }
  }, [locationDetails, setValue, locationDetailsValues]);

  useEffect(() => {
    (async () => {
      const { data: locationsData } = await locationsService.list();

      setLocations(locationsData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const locationsDetailsPayload = await locationDetailsService.listValues();

      setLocationDetailsValues(locationsDetailsPayload?.data);

      cleanLocationDetailsInexistent();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cleanLocationDetailsInexistent();
  }, [cleanLocationDetailsInexistent, locationDetails]);

  return (
    <React.Fragment>
      <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={2}>
        <Heading fontSize="xl" fontWeight="semibold">
          Localização
        </Heading>
      </GridItem>

      <GridItem colStart={1} colSpan={4} mt={8}>
        <Select
          isDisabled={manual}
          label="Algumas opções de localização"
          placeholder="selecione a localização aqui..."
          value={getValueOption()}
          options={renderOptionsLocation(locations)}
          onChange={(e) => handleSelectLocation(e.target.value)}
          error={
            errors?.location?.state?.message ||
            errors?.location?.city?.message ||
            errors?.location?.address?.message
          }
        />
      </GridItem>

      <GridItem colStart={1} colSpan={4} mt={6}>
        <CollapseSectionText
          isOpen={!!state || !!city || !!address}
          title="Ou adicione manualmente"
          textProps={{
            fontSize: "xs",
            color: "gray.500",
          }}
          onClick={loadDataManualLocation}
        >
          <FormLocation space={4} user={user} />
        </CollapseSectionText>
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <TextField
          type="text-area"
          label="Ponto de encontro"
          placeholder=" "
          fullWidth
          helperText="Detalhe onde os alunos deverão se encontrar com o instrutor."
          inputProps={{
            minH: "15vh",
            ...register("landmark"),
          }}
          error={errors?.landmark?.message}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <Flex align="center" justify="space-between">
          <Text fontSize="sm" color="neutral.darkest" fontWeight="bold">
            Como é a praia?
          </Text>
          <Button
            variant="outline"
            size="xs"
            onClick={useLocationDetailsDefault}
          >
            Utilizar padrão
          </Button>
        </Flex>
      </GridItem>

      {locationDetailsValues?.map((option, index) => (
        <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
          <Checkbox
            value={option.value}
            isChecked={
              !!(locationDetails || [])?.find((l) => l.value === option.value)
            }
            onChange={(event) => {
              if (event?.target?.checked) {
                setValue("locationDetails", [
                  ...(locationDetails || []),
                  option,
                ]);
              } else {
                setValue(
                  "locationDetails",
                  (locationDetails || []).filter(
                    (l) => l.value !== option.value
                  )
                );
              }
            }}
          >
            {option.label}
          </Checkbox>
        </GridItem>
      ))}

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <TextField
          label="Outro"
          placeholder=" "
          fullWidth
          value={newLocationDetail}
          onChange={(e) => setNewLocationDetail(e.target.value)}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={4}>
        <Collapse in={!!newLocationDetail} animateOpacity>
          <Flex align="center" justify="space-between">
            <Button variant="outline" onClick={() => setNewLocationDetail("")}>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setLocationDetailsValues([
                  ...(locationDetailsValues || []),
                  {
                    label: newLocationDetail?.trim(),
                    value: newLocationDetail?.trim(),
                  } as ILocationDetails,
                ]);

                setNewLocationDetail("");
              }}
            >
              Adicionar
            </Button>
          </Flex>
        </Collapse>
      </GridItem>
    </React.Fragment>
  );
}
