import React from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

import { GridItem, Heading } from "@chakra-ui/react";

import NumberInput from "../../../../components/inputs/number";
import RadioGroup from "../../../../components/radio";
import Select from "../../../../components/select";
import TextField from "../../../../components/text-field";
import { useResposiveness } from "../../../../hooks/responsiveness";

export enum LessonModality {
  SURF = "SURF",
  KITE = "KITE",
  SUP = "SUP",
  BODYBOARD = "BODYBOARD",
  OTHER = "OTHER",
}

export const lessonModalityOptions = [
  { label: "Surf", value: LessonModality.SURF },
  { label: "Kitesurf", value: LessonModality.KITE },
  { label: "Sup (standup paddle)", value: LessonModality.SUP },
  { label: "Bodyboard", value: LessonModality.BODYBOARD },
  { label: "Outro", value: LessonModality.OTHER },
];

export enum LessonLevel {
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
  COMPETITION = "COMPETITION",
  OTHER = "OTHER",
}

export const lessonLevelOptions = [
  { label: "Iniciante", value: LessonLevel.BEGINNER },
  { label: "Intermediário", value: LessonLevel.INTERMEDIATE },
  { label: "Avançado", value: LessonLevel.ADVANCED },
  { label: "Competição", value: LessonLevel.COMPETITION },
  { label: "Outro", value: LessonLevel.OTHER },
];

export const formSchema = {
  name: yup.string().min(3).required(),
  modality: yup
    .string()
    .oneOf(Object.values(LessonModality))
    .nullable()
    .required("Campo obrigatório"),
  level: yup.string().oneOf(Object.values(LessonLevel)).nullable().required(),
  seats: yup.number().min(1).required(),
  description: yup.string().min(5).required().default(""),
};

export default function StepSetup() {
  const { isMobile } = useResposiveness();
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const modality = watch("modality");
  const seats = watch("seats");

  return (
    <React.Fragment>
      <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={2}>
        <Heading fontSize="xl" fontWeight="semibold">
          Configuração
        </Heading>
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <TextField
          label="Nome da aula"
          placeholder="Surf na joaquina"
          fullWidth
          inputProps={register("name")}
          error={errors?.name?.message}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <RadioGroup
          label="Modalidade"
          options={lessonModalityOptions}
          radioProps={{
            value: modality,
            onChange: (value: string) =>
              setValue("modality", value, {
                shouldValidate: true,
              }),
          }}
          error={errors?.modality?.message}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={4} mt={8}>
        <Select
          label="Nível da aula"
          placeholder="selecione um nível"
          options={lessonLevelOptions}
          selectProps={{
            value: watch("level"),
            onChange: (event: any) => {
              setValue("level", event?.target?.value, {
                shouldValidate: true,
              });
            },
          }}
          error={errors?.level?.message}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <NumberInput
          label="Quantidade de vagas"
          inputProps={register("seats", {
            required: "Numero de vagas é obrigatório",
            min: 1,
            valueAsNumber: true,
            value: seats,
          })}
          error={errors?.seats?.message}
          numberInputProps={{
            defaultValue: 0,
            min: 1,
            value: seats,
          }}
          onChange={(value) =>
            setValue("seats", value, {
              shouldValidate: true,
            })
          }
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <TextField
          label="Descrição de aula"
          placeholder=" "
          fullWidth
          type="text-area"
          helperText="Descreva um pouco como será a aula, onde os alunos devem se encontrar..."
          inputProps={register("description", {
            required: "Descrição é obrigatória",
          })}
          error={errors?.description?.message}
        />
      </GridItem>
    </React.Fragment>
  );
}
