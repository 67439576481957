import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment-timezone";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
  Box,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
  useToken,
  Link,
  Button,
  Image,
  Divider,
} from "@chakra-ui/react";

import { IStudent } from "@isurf-tech/types/domain";
import { Gender, PracticeFrequency } from "@isurf-tech/types/domain/enums";

import { GenderLabel } from "../../@types/app";
import { MediaContext, MediaObject } from "../../@types/medias";
import ArrowRight from "../../assets/icons/arrow-right";
import UserIcon from "../../assets/icons/user";
import UploadButton from "../../components/galery/upload-button";
import Header from "../../components/headers/main";
import SimpleHeader from "../../components/headers/simple";
import DatePicker from "../../components/inputs/date-picker";
import PhoneInput from "../../components/inputs/phone";
import Select from "../../components/select";
import TextField from "../../components/text-field";
import routesName from "../../config/routes";
import { useAuth } from "../../hooks/auth";
import { useDialog } from "../../hooks/dialog";
import { useLoader } from "../../hooks/loader";
import { useResposiveness } from "../../hooks/responsiveness";
import useLocalStorage from "../../hooks/storage";
import { mediasService, studentService } from "../../services/api";

const SPACING = 6;

enum PerfilAction {
  CREATE = "create",
  UPDATE = "update",
}

const formSchema = yup.object().shape({
  birthDate: yup.string().required("Campo obrigatório"),
  gender: yup.string().required("Campo obrigatório"),
  weight: yup.string().required("Campo obrigatório"),
  height: yup.string().required("Campo obrigatório"),
  goalDescription: yup.string().required("Campo obrigatório"),
  frequency: yup.string().required("Campo obrigatório"),
  avatar: yup.string().required("Campo obrigatório"),
});

const formUpdateSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
});

export default function PerfilStudentPage() {
  const navigate = useNavigate();
  const loader = useLoader();
  const { isMobile, isTablet } = useResposiveness();
  const [brandDarkColor] = useToken("colors", ["brand.dark"]);
  const { user: student, refreshUser } = useAuth<IStudent>();
  const isUpdate = useMemo(() => !!student?.id, [student]);
  const [form, setForm] = useLocalStorage("perfil-form-student", student);
  const dialog = useDialog();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isUpdate ? formSchema : formSchema.concat(formUpdateSchema)
    ),
    defaultValues:
      (student
        ? {
            ...student,
            avatar: student?.user?.avatar,
            name: student.user.name,
            email: student.user.email,
            cellphone: `+${student.user.cellphone.replace("+", "")}`,
          }
        : null) || form,
  });

  const uploadLogoImage = async (files: File[] = []): Promise<void> => {
    const [file] = files;

    await loader.run();

    try {
      const { data: uploadUrl } = await mediasService.getUploadUrl({
        context: MediaContext["FORM-MEDIAS"],
        object: MediaObject.PERFIL,
        name: file.name,
      });

      const { data: url } = await mediasService.uploadByUrl(uploadUrl, file);

      setValue("avatar", url, {
        shouldValidate: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      await loader.stop();
    }
  };

  const onSubmit = async (form: any) => {
    const action = isUpdate ? PerfilAction.UPDATE : PerfilAction.CREATE;

    await loader.run();

    const { data, error } = await studentService?.[action](form);

    await loader.stop();

    if (action === PerfilAction.UPDATE) await refreshUser();

    if (
      !error &&
      ((data?.record && data?.record?.id) || action === PerfilAction.UPDATE)
    ) {
      return dialog.open({
        title: "Sucesso",
        type: "success",
        description: "Perfil atualizado com sucesso!",
        onClose: () => navigate(routesName.studentsDashboard),
      });
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setForm(value);
    });

    return () => subscription.unsubscribe();
  }, [watch, setForm]);

  const birthDate = watch("birthDate", form?.birthDate);
  const avatar = watch("avatar", student?.user?.avatar || form?.avatar || "");

  return (
    <Box w="100vw" pb="5%">
      {isUpdate ? <Header /> : <SimpleHeader title="Conclusão da conta" />}

      <Box px="6%" mt={SPACING} as="form" onSubmit={handleSubmit(onSubmit)}>
        <Heading fontSize={isMobile ? "xl" : "2xl"}>Perfil do aluno</Heading>

        <SimpleGrid
          columns={isMobile ? 1 : isTablet ? 3 : 12}
          mt={SPACING}
          rowGap={2}
        >
          <GridItem colSpan={4}>
            <Flex>
              <Box
                borderRadius="full"
                bg="brand.light"
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="140px"
                h="140px"
              >
                {!!avatar ? (
                  <Image src={avatar} w="140px" h="140px" borderRadius="full" />
                ) : (
                  <UserIcon size={70} color={brandDarkColor} />
                )}
              </Box>

              <Box
                display="flex"
                flexDir="column"
                justifyContent="center"
                ml={4}
              >
                <Text fontSize="sm">Foto do perfil</Text>

                <UploadButton onUpload={uploadLogoImage}>
                  <Link size="small">Alterar image</Link>
                </UploadButton>
              </Box>
            </Flex>
          </GridItem>

          <GridItem
            colStart={isMobile ? 1 : 5}
            colSpan={5}
            mt={isMobile ? 4 : 0}
          >
            <SimpleGrid spacing={isMobile ? SPACING + 4 : SPACING + 2}>
              <Heading fontSize="lg" color="neutral.darkest">
                Dados pessoais
              </Heading>

              <TextField
                label="Nome completo"
                placeholder="Nome completo"
                inputProps={register("name")}
                error={errors?.name?.message}
                fullWidth
              />

              <DatePicker
                label="Data de nascimento"
                value={
                  moment(birthDate).isValid()
                    ? (moment(birthDate, "DD-MM-YYYY")
                        .startOf("day")
                        .toDate() as Date)
                    : moment().startOf("day").toDate()
                }
                inputProps={register("birthDate")}
                error={errors?.birthDate?.message}
                onChange={(date: Date) =>
                  setValue("birthDate", date?.toISOString())
                }
                datePickerProps={{
                  dateFormat: "dd/MM/yyyy",
                }}
              />

              <Select
                label="Sexo"
                placeholder="Selecione o sexo"
                options={[
                  { label: GenderLabel[Gender.MALE], value: Gender.MALE },
                  { label: GenderLabel[Gender.FEMALE], value: Gender.FEMALE },
                ]}
                selectProps={register("gender")}
                error={errors?.gender?.message}
              />

              <TextField
                label="Informe seu peso (kg)"
                placeholder="00"
                type="number"
                rightIcon={
                  <Box>
                    <Text>kg</Text>
                  </Box>
                }
                helperText="Será importante para escolha do equipamento"
                inputProps={{ step: "any", ...register("weight") }}
                error={errors.weight?.message}
              />

              <TextField
                label="Informe sua altura (cm)"
                placeholder="00"
                type="number"
                rightIcon={
                  <Box>
                    <Text>kg</Text>
                  </Box>
                }
                helperText="Será importante para escolha do equipamento"
                inputProps={{ step: "any", ...register("height") }}
                error={errors.height?.message}
              />

              <Select
                label="Com qual frequência você surfa?"
                placeholder="Selecione a frequência"
                options={[
                  { label: "Nunca Surfei", value: PracticeFrequency.NEVER },
                  {
                    label: "Surfei poucas vezes",
                    value: PracticeFrequency.FEW_TIMES,
                  },
                  {
                    label: "Surfei algumas vezes",
                    value: PracticeFrequency.SOMETIMES,
                  },
                  {
                    label: "Sou pro no surf",
                    value: PracticeFrequency.ALWAYS,
                  },
                ]}
                selectProps={register("frequency")}
                error={errors?.frequency?.message}
              />

              <Divider />

              <Heading fontSize="lg" color="neutral.darkest">
                Contato
              </Heading>

              <PhoneInput
                name="cellphone"
                label="Celular"
                control={control}
                placeholder="(00) 00000-0000"
                error={errors?.cellphone?.message}
                isDisabled
              />

              <TextField
                isDisabled
                label="Email"
                inputProps={register("email")}
                error={errors?.email?.message}
                fullWidth
              />

              <Divider />

              <Heading fontSize="lg" color="neutral.darkest">
                Objetivo
              </Heading>

              <TextField
                label="Conte para gente qual seu objetivo com o as aulas"
                placeholder="Descreve o que você o que você espera alcançar com as aulas"
                helperText="Isso irá ajudar no preparo da aula"
                type="text-area"
                fullWidth
                inputProps={{
                  minHeight: "15vh",
                  ...register("goalDescription"),
                }}
                error={errors.goalDescription?.message}
              />

              <Divider />

              <Heading fontSize="lg" color="neutral.darkest">
                Segurança
              </Heading>

              <Link
                display="flex"
                flexDir="row"
                alignItems="center"
                padding="8px 0px"
                fontSize="sm"
                color="neutral.darkest"
                cursor="pointer"
                textDecoration="unset"
                onClick={() => navigate(routesName.recoveryPassword)}
              >
                Alterar senha
                <ArrowRight style={{ marginLeft: 4 }} />
              </Link>

              <Button type="submit" size="lg">
                Salvar
              </Button>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
