import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";

import ArrowRight from "../../../../assets/icons/arrow-right";
import { useResposiveness } from "../../../../hooks/responsiveness";
import useLocalStorage from "../../../../hooks/storage";
import { cmsService } from "../../../../services/api";
import { CMS_CONTENTS_NAMES } from "../../../../services/api/services/cms";

interface IBestBeach {
  index: number;
  name: string;
  value: string;
  description: string;
  image: string;
}

export default function HomeMainThirdSection() {
  const { isMobile } = useResposiveness();
  const [currentBeach, setCurrentBeach] = useState<IBestBeach | null>(null);
  const [bestBeachs, setBestBeachs] = useLocalStorage<IBestBeach[]>(
    "best-beaches",
    []
  );

  useEffect(() => {
    (async () => {
      const { data = [] } = await cmsService.getContents<IBestBeach[]>(
        CMS_CONTENTS_NAMES.BEST_BEACHES
      );

      if (data) setBestBeachs(data);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectBeach = (beach: IBestBeach | null) => {
    setCurrentBeach(beach);
  };

  if (!bestBeachs?.length) return null;

  return (
    <Box
      id="home-main-third"
      as="section"
      position="relative"
      minHeight={isMobile ? "85vh " : "85vh"}
      mt={isMobile ? 16 : "0px"}
    >
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={8}
        padding={isMobile ? "0px 16px" : "0px 80px"}
      >
        <GridItem colStart={1} colSpan={isMobile ? 12 : 5}>
          <Heading fontSize={isMobile ? "lg" : "xl"}>
            As melhores praias para surfe
          </Heading>
        </GridItem>
      </Grid>

      <Box
        display="flex"
        flexDirection="row"
        mt="22px"
        overflow="auto"
        overflowX="scroll"
        whiteSpace="nowrap"
        pl="16px"
        padding={isMobile ? "0px 0px" : "0px 80px"}
      >
        {bestBeachs
          .sort((a, b) => Number(a?.index) - Number(b?.index))
          .map((beach, index) => (
            <Button
              key={index.toString()}
              variant="outline"
              size="sm"
              ml={index > 0 ? 2 : 0}
              w="max-content"
              minW="min-content"
              onClick={() => handleSelectBeach(beach)}
              borderRadius="full"
              _active={{
                borderRadius: "full",
                bg: "neutral.light",
              }}
              isActive={currentBeach?.name === beach.name}
            >
              {beach.name}
            </Button>
          ))}
      </Box>

      <Grid
        gridTemplateColumns="repeat(12, 1fr)"
        templateAreas={
          isMobile
            ? `
              "image"
              "description"
              "seeMore"
            `
            : `
              "image description"
              "image seeMore"
            `
        }
        gap={8}
        margin={isMobile ? "32px 0px 0px 0px" : "40px 80px 0px 80px"}
      >
        <GridItem area="image" colStart={1} colSpan={isMobile ? 12 : 7}>
          <Image
            src={currentBeach?.image || bestBeachs?.[0]?.image}
            w="100%"
            borderRadius="lg"
            loading="lazy"
            maxH="60vh"
          />
        </GridItem>

        <GridItem
          area="description"
          colStart={isMobile ? 1 : 8}
          colSpan={isMobile ? 12 : 4}
          colEnd={11}
          px="16px"
        >
          <Text fontWeight={600} fontSize={24}>
            {`"${currentBeach?.description || bestBeachs?.[0]?.description}"`}
          </Text>
        </GridItem>

        <GridItem
          area="seeMore"
          colStart={isMobile ? 1 : 8}
          colSpan={isMobile ? 12 : 4}
          display="flex"
          flexDirection="row"
          alignItems="end"
          padding="8px"
          px="16px"
        >
          <Link
            textDecoration="none"
            display="flex"
            flexDirection="row"
            alignItems="center"
            href={`/search?localization=${
              currentBeach?.value || bestBeachs?.[0]?.value
            }`}
            w="max-content"
          >
            <Heading fontSize="sm" color="neutral.darkest">
              {" "}
              Ver aulas na {currentBeach?.name || bestBeachs?.[0]?.name}
            </Heading>
            <Flex m={2} justify="center" alignItems="center">
              <ArrowRight />
            </Flex>
          </Link>
        </GridItem>
      </Grid>
    </Box>
  );
}
