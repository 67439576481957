import { ISchedule } from "@isurf-tech/types/domain";

import { FilterPagination } from "./app";

export enum LessonModality {
  SURF = "SURF",
  KITE = "KITE",
  SUP = "SUP",
  BODYBOARD = "BODYBOARD",
  OTHER = "OTHER",
}

export enum LessonLevel {
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
  COMPETITION = "COMPETITION",
  OTHER = "OTHER",
}

export type Schedules = {
  [key in string]: ISchedule[];
};

export type LessonCreateParams = {
  name: string;
  level: string;
  seats: number;
  description: string;
  modality: string;
  price: number;
  location: string;
  landmark: string;
  locationDetails: { label: string; value: string }[];
  adStartDate: string;
  adEndDate: string;
  duration: number;
  recurrence: boolean;
  schedules: Schedules;
  photos: string[];
  limitDateSubscription: number;
  limitDateCancelation: number;
};

export interface QueryLessonsDto extends FilterPagination {
  localization?: string;
  school?: string;
  start?: string;
  end?: string;
  modality?: string;
}
