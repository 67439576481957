import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Stack,
} from "@chakra-ui/react";

import { ISchedule } from "@isurf-tech/types/domain";

import CardSchedule from "../../components/cards/schedule";
import DatePicker from "../../components/date-picker";
import DatePickerRow from "../../components/date-picker-row";
import EmptyList from "../../components/empty-list";
import Header from "../../components/headers/main";
import BoxInput from "../../components/inputs/box";
import Paginator from "../../components/paginator";
import routesName from "../../config/routes";
import { useResposiveness } from "../../hooks/responsiveness";
import { useSchedules } from "../../hooks/schedules";
import { scheduleService } from "../../services/api";

const sumLengths = (
  store: { lessonLength: number; studentsLength: number },
  schedule: ISchedule
) => {
  store.lessonLength += 1;
  store.studentsLength += schedule?.classroom?.students?.length || 0;

  return store;
};

export default function SchoolDashboard() {
  const navigate = useNavigate();
  const { isMobile } = useResposiveness();
  const {
    loading: loadingSchedules,
    schedules,
    updateFilters,
    pagination,
    updatePage,
  } = useSchedules();
  const { studentsLength } = useMemo(
    () =>
      Object.values(schedules || {})
        .flat()
        .reduce(sumLengths, { lessonLength: 0, studentsLength: 0 }),
    [schedules]
  );

  const [countClassByDay, setCountClassByDay] = useState<
    Record<string, number>
  >({});
  const [currentDate, setCurrentDate] = useState(moment().toISOString());
  const classrooms = useMemo(
    () => schedules?.[moment(currentDate).startOf("day").toISOString()] || [],
    [currentDate, schedules]
  );

  const renderLabelDay = (day: string): any => {
    const startDay = moment(day).startOf("day").toISOString();
    const countDay = countClassByDay[startDay];

    if (!countDay) return `sem aulas`;

    return `${countDay} aula${countDay > 1 ? "s" : ""}`;
  };

  useEffect(() => {
    updateFilters({
      start: moment(currentDate).startOf("day").toISOString(),
      end: moment(currentDate).endOf("day").toISOString(),
      page: pagination.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFilters({
      start: moment(currentDate).startOf("day").toISOString(),
      end: moment(currentDate).endOf("day").toISOString(),
      page: pagination.page,
    });

    return () => {
      updateFilters({
        start: "",
        end: "",
        page: 0,
      });
    };
  }, [currentDate, updateFilters, pagination.page]);

  useEffect(() => {
    (async () => {
      const { data: count, error } = await scheduleService.countByDay(
        currentDate
      );

      if (!error && Object.keys(count).length) setCountClassByDay(count);
    })();
  }, [currentDate]);

  return (
    <Box w="100vw">
      <Grid
        templateAreas={
          isMobile
            ? `
        "header"
        "title"
        "button"
        "date-picker"
        "calendar-row"
        "classes-header"
        "classes"
        "footer"
      `
            : `
      "header header"
      "title calendar-row"
      "calendar classes-header"
      "calendar classes"
      "calendar footer"
    `
        }
        templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
        gap={8}
        pb={isMobile ? 20 : 10}
        pl={isMobile ? "16px" : "4%"}
        pr={isMobile ? "16px" : "2.5%"}
      >
        <GridItem
          area="header"
          colStart={1}
          colSpan={isMobile ? 4 : 12}
          ml={isMobile ? "-16px" : "-4.5%"}
          mr={isMobile ? "-16px" : "-3%"}
        >
          <Header
            buttonRedirect={
              isMobile
                ? null
                : {
                    label: "Gerenciar aulas",
                    redirect: routesName.schoolsCreateClass,
                  }
            }
          />
        </GridItem>

        <GridItem area="title" colStart={1} colSpan={isMobile ? 4 : 3}>
          <Heading fontSize={isMobile ? "xl" : "lg"} fontWeight="semibold">
            Aulas agendadas
          </Heading>
        </GridItem>

        {isMobile && (
          <GridItem area="button" colSpan={4}>
            <Button
              size="lg"
              w="100%"
              onClick={() => navigate(routesName.schoolsCreateClass)}
            >
              Gerenciar aulas
            </Button>
          </GridItem>
        )}

        {isMobile && (
          <GridItem area="date-picker" colSpan={4}>
            <BoxInput
              label="Data"
              type="date"
              value={moment(currentDate).format("YYYY-MM-DD")}
              onChange={(e) =>
                setCurrentDate(
                  moment(e.target.value, "YYYY-MM-DD").toISOString()
                )
              }
              min={moment().subtract(180, "days").format("YYYY-MM-DD")}
            />
          </GridItem>
        )}

        {!isMobile && (
          <GridItem
            area="calendar"
            colStart={1}
            colSpan={isMobile ? 4 : 3}
            mt="-5vh"
          >
            <Box ml="-20px">
              <DatePicker
                current={moment(currentDate).toDate()}
                onChange={(day) => setCurrentDate(moment(day).toISOString())}
                minDate={moment().subtract(180, "days").toDate()}
              />
            </Box>
          </GridItem>
        )}

        <GridItem
          area="calendar-row"
          colStart={isMobile ? 1 : 4}
          colSpan={isMobile ? 4 : 9}
          mx={isMobile ? "-16px" : 0}
        >
          <DatePickerRow
            current={moment(currentDate).toDate()}
            onClick={(day) => setCurrentDate(day)}
            // dayLabel={`${pagination?.count || 0} aulas`}
            dayLabel={renderLabelDay}
          />
        </GridItem>

        <GridItem
          area="classes-header"
          colStart={isMobile ? 1 : 4}
          colSpan={isMobile ? 4 : 9}
        >
          {!isMobile && <Divider mb={4} />}
          <Heading
            fontSize={isMobile ? "md" : "sm"}
            mt={2}
          >{`${pagination.count} aulas · ${studentsLength} alunos`}</Heading>
        </GridItem>

        <GridItem
          area="classes"
          colStart={isMobile ? 1 : 4}
          colSpan={isMobile ? 4 : 9}
        >
          <Box
            mt={isMobile ? 0 : 4}
            maxH={isMobile ? "80vh" : "50vh"}
            overflowY="scroll"
          >
            {loadingSchedules ? (
              <Stack p={0}>
                <Skeleton height="100px" borderRadius="sm" />
                <Skeleton height="100px" borderRadius="sm" />
                <Skeleton height="100px" borderRadius="sm" />
              </Stack>
            ) : !classrooms.length ? (
              <Flex direction="column" mt={2}>
                <EmptyList
                  title="Nenhuma aula hoje"
                  description="Você não tem nenhuma aula agendada para hoje. Clique no botão abaixo para criar aulas e ter alunos matriculados."
                  buttonLabel="Criar aula"
                  onClick={() => navigate(routesName.schoolsCreateClass)}
                />
              </Flex>
            ) : (
              classrooms.map((schedule, index) => (
                <CardSchedule
                  key={index.toString()}
                  mt={index > 0 ? 2 : 0}
                  schedule={schedule}
                  onClick={() =>
                    navigate(
                      routesName.schoolsClassroomById.replace(
                        ":id",
                        schedule.classroom.id
                      ),
                      {
                        state: {
                          schedule,
                        },
                      }
                    )
                  }
                />
              ))
            )}
          </Box>
        </GridItem>

        <GridItem
          area="footer"
          pb={isMobile ? "32px" : "0px"}
          colStart={isMobile ? 1 : 4}
          colSpan={isMobile ? 4 : 9}
        >
          <Flex
            direction="row"
            align="center"
            justify={isMobile ? "center" : "flex-end"}
          >
            {/* <Flex direction={isMobile ? "column-reverse" : "row"}>
              <Button
                size={isMobile ? "lg" : "md"}
                variant="outline"
                color="feedback.danger.dark"
                borderColor="feedback.danger.dark"
                mt={isMobile ? 4 : 0}
                _hover={{
                  bg: "feedback.danger.dark !important",
                }}
              >
                Cancelar turmas restantes
              </Button>
              <Button
                size={isMobile ? "lg" : "md"}
                variant="outline"
                ml={isMobile ? 0 : 2}
              >
                Enviar Mensagem
              </Button>
            </Flex> */}

            <Paginator
              disabled={pagination.count <= 0 || loadingSchedules}
              page={pagination.page}
              count={pagination.count}
              offset={pagination.offset}
              onChange={(page) => updatePage(page)}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
}
