import { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

export class LocationDetailsService {
  constructor(private api: AxiosInstance) {}

  async listValues(): Promise<ServiceResponse> {
    return {
      data: [
        {
          label: "Tem restaurante por perto",
          value: "TEM_RESTAURANTE_POR_PERTO",
        },
        {
          label: "Bom para iniciante",
          value: "BOM_PARA_INICIANTE",
        },
        {
          label: "Tem estacionamento",
          value: "TEM_ESTACIONAMENTO",
        },
        {
          label: "Surf avançado",
          value: "SURF_AVANCADO",
        },
        {
          label: "Mar calmo",
          value: "MAR_CALMO",
        },
        {
          label: "Praia badalada",
          value: "PRAIA_BADALADA",
        },
        {
          label: "Boas ondas",
          value: "BOAS_ONDAS",
        },
        {
          label: "Paradisíaca",
          value: "PARADISIACA",
        },
      ],
      error: null,
    };
  }
}
