import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

export enum CMS_CONTENTS_NAMES {
  BEST_BEACHES = "best-beaches",
}

export class CMSService {
  constructor(private api: AxiosInstance) {}

  async getContents<T>(tableName: CMS_CONTENTS_NAMES): Promise<ServiceResponse<T>> {
    let result = { data: null, error: null } as ServiceResponse;

    try {
      const { data } = await this.api.get(`cms/contents/${tableName}`);

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
